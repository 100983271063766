<template>
  <transition name="slide">
    <section
      v-if="data.sub_items"
      class="menu-contents"
    >
      <div class="menu-contents__wrapper">
        <div
          class="menu-contents__column menu-contents__items"
          :style="columns"
        >
          <div
            v-for="(items, items_index) in sub_items_arrays"
            :key="`menu-contents-items-${items_index}`"
            class="menu-contents__items-column"
          >
            <router-link
              v-for="({ sub_category, ...rest }, item_index) in items"
              :key="`menu-contents-item-${item_index}`"
              :to="`/Products/${data.category}/${sub_category}`"
              :event="admin_imported ? '' : 'click'"
              class="link"
              itemprop="link"
              :aria-label="`Checkout our ${sub_category}`"
              @click.native="$emit('handle_click')"
            >
              <p class="menu-contents__item">
                {{
                  get_correct_translation_value(rest, "name", [selected_language])
                }}
              </p>
            </router-link>
          </div>
        </div>

        <div
          class="menu-contents__column"
        >
          <preview_products
            v-if="any_favorite_products || !collection_image"
            :product_ids="preview_products"
            :admin_imported="admin_imported"
            :show_mobile="false"
          />
          <div
            v-else-if="collection_image"
            class="menu-contents__collection-image"
            @click="$router.push(`/Products/${data.category}`)"
          >
            <div>
              <v-lazy-image
                :src="collection_image"
                :alt="data.name"
                itemprop="image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"
import { CLIENT_STORE } from "../../constants/other"
import preview_products from "./preview_products"
import un_bind from "../../../Shared/methods/un_bind"
import { PRODUCTS_STORE } from "../../../Admin/constants/others_constants"
import get_image_src from "../../../Shared/methods/get_image_src"
import { image_dimension_names } from "../../../../data/other_constants"

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    admin_imported: {
      type: Boolean,
      required: false
    },
  },
  components: {
    preview_products,
    VLazyImage
  },
  data() {
    return {
      show_extra_contents: false,
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "selected_language",
      "collections"
    ]),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "shopify_collections",
    ]),
    correct_collections() {
      return this.admin_imported ? [...this.imported_collections, ...this.shopify_collections] : this.collections
    },
    get_collection() {
      return this.correct_collections.find(({ id }) => id === this.data.id) || {}
    },
    collection_image() {
      return this.get_collection?.image || get_image_src(this.get_collection, image_dimension_names.tablet)
    },
    any_favorite_products() {
      return this.get_collection?.products?.some(({ is_favorite }) => is_favorite)
    },
    sub_items_arrays() {
      return (this.data.sub_items || []).reduce((tot, item, index) => {
        let correct_tot_index;

        if (index < 4) correct_tot_index = 0
        if (index > 3 && index < 8) correct_tot_index = 1
        if (index > 7 && index < 12) correct_tot_index = 2
        if (index > 11 && index < 16) correct_tot_index = 3

        if (correct_tot_index !== undefined) tot[correct_tot_index] = [...(tot[correct_tot_index] || []), item]

        return tot
      }, [])
    },
    preview_products() {
      const products = (
        this.data.parent_category ?
          this.correct_collections.filter(
            ({ id: collection_id }) => this.data.sub_items.some(({ id }) => id === collection_id)
          ).flatMap(({ products }) => products || []) :
          un_bind(this.get_collection).products
      ) || []

      products.sort((a = {}, b = {}) => {
        if (a.is_favorite && !b.is_favorite) return -1
        if (b.is_favorite && !a.is_favorite) return 1
        if (b.is_favorite && a.is_favorite) return 0

        return -1
      })

      return products
        .map(({ id }) => id)
        .slice(0, 4)
    },
    columns() {
      return {
        gridTemplateColumns: `repeat(${this.sub_items_arrays.length}, 1fr)`
      }
    },
  },
  methods: {
    get_correct_translation_value,
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/slide_in_animation" as *;

  .menu-contents {
    position: absolute;
    top: $menu-height;
    left: 0px;
    height: $menu-contents-height;
    background: var(--body_color);
    border-bottom: var(--background_heavy) 1px solid;
    width: 100%;
    box-shadow: 0 2px 6px 0 rgba($pure-black, 0.2);

    &__wrapper {
      margin: auto;
      max-width: $max-menu-width;
      padding: 10px 21px 30px 31px;

      @media (max-width: $max-menu-width) {
        padding: 10px 26px 30px 35px;
      }
    }

    &__column {
      display: inline-block;
      width: 50%;
      height: calc(#{$menu-contents-height} - 40px);
      padding-top: 10px;
    }

    &__items {
      display: inline-grid;
      vertical-align: top;
      font-weight: 400;
      font-size: 17px;
      grid-column: 1;
      padding: 10px 0;

      &:first-child{
        border-left: none;
      }
    }

    &__item {
      position: relative;
      color: var(--background_heavy);
      height: 25%;
      margin: 0;
      padding: 10px 10px 20px 0;

      &:hover {
        &:before {
          visibility: visible;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }

      &:before {
        content: "";
        position: absolute;
        width: 30px;
        height: 1px;
        bottom: 12px;
        left: 1px;
        background-color: var(--font_heavy);
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
      }
    }

    &__first-column-items {
      grid-column: 1;
    }

    &__collection-image {
      width: 100%;
      height: 100%;
      transition: 300ms ease-in;
      border-radius: $border-radius;
      overflow: hidden;
      padding: 10px 20px 0 0;

      div {
        height: 100%;
        width: 100%;
        max-width: 600px;
        clip-path: polygon(5% 0, 100% 0, 100% 85%, 95% 100%, 0 100%, 0 15%);
        cursor: pointer;
        margin: auto;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scale(1.1);
        transition: $default-transition;
      }
 
      &:hover {
        img {
          transform: scale(1);
        }
      }
    }
  }

  .slide-enter-active {
    animation: slide-in .3s;
  }
  .slide-leave-active {
    animation: slide-in .3s reverse;
  }
</style>
